document.querySelector('.headerbar__navtoggler').addEventListener('click', () => {

    if (document.querySelector("body").classList.contains('activeSearch')){
        document.querySelector("body").classList.remove('activeSearch');
        document.documentElement.classList.remove('activeSearch');
        return;
    }
    document.querySelector('body').classList.toggle('active');
});



document.querySelector('.headerbar__search__toggler').addEventListener('click', () => {
    document.querySelector('body').classList.toggle('activeSearch');
    document.documentElement.classList.add('activeSearch');
});

for (let parentNavElement of document.querySelectorAll('.headerbar__mainnav .parent')) {
    parentNavElement.querySelector('a, span').addEventListener('click', (e) => {
        if (parentNavElement.classList.contains('activeNav')) {
            parentNavElement.classList.remove('activeNav');
            e.preventDefault();
            return;
        }

        if (window.innerWidth >= 1320) {
            if (parentNavElement.parentNode.classList.contains('mod-menu__sub')) {
                return;
            }
            for (let activeNavElement of document.querySelectorAll('.headerbar__mainnav .activeNav')) {
                activeNavElement.classList.remove('activeNav');
            }
        }

        e.preventDefault();
        parentNavElement.classList.toggle('activeNav')
    })
}
